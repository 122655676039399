@font-face {
    font-family: 'gd-sage';
    src: url('../fonts/gd-sage-bold.woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Regular.woff2'),
         url('../fonts/Metropolis-Regular.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-SemiBold.woff2'),
         url('../fonts/Metropolis-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Bold.woff2'),
         url('../fonts/Metropolis-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.woff2'),
          url('../fonts/Inter-Regular.woff');
    font-weight: 400;
    font-style: normal;
}