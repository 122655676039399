@import 'fonts.scss';
* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Metropolis', sans-serif;
}
h1 {
    font-size: 64px;
    line-height: 72px;
}
h2 {
    font-size: 56px;
    line-height: 64px;
}
h3 {
    font-size: 40px;
    line-height: 44px;
  
}
h4 {
    font-size: 32px;
    line-height: 36px;
}
h5 {
    font-size: 24px;
    line-height: 28px;
}
h6 {
    font-size: 20px;
    line-height: 20px;
}

#welcome {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.3);
    &.exiting {
        opacity: 0;
        transition: opacity .4s;
        #welcome-content {
            animation: fly-out .3s;
        }
    }
    #welcome-content {
        position: absolute;
        background: #ffffff;
        width: 20%;
        height: 30%;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0px 8px 20px rgba(0,0,0,0.13);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        animation: pop-in 1s;
        h1 {
            color: #313131;
        }
        p {
            color: #686868;
            margin-bottom: 10px;
            padding: 0 30px;
            &:nth-child(6) {
                color: #000000;
            }
        }
        .popper {
            position: absolute;
            top: -30px;
            font-size: 50px;
            user-select: none;
            &:nth-child(1) {
                left: -40px;
                transform: scale(-1, 1);
            }
            &:nth-child(2) {
                right: -40px;
            }
            &:hover {
                cursor: default;
            }
        }
        .welcome-button {
            flex-basis: 30%;
            background-color: #1178ff;
            color: #ffffff;
            font-weight: 600;
            font-size: 18px;
            padding: 5px 20px;
            border-radius: 5px;
            user-select: none;
            transition: box-shadow .3s;
            &:hover {
                cursor: pointer;
                box-shadow: 0px 8px 20px rgba(0, 110, 255, 0.397);
            }
        }
    }
    z-index: 9999;
}

#navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 8%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    background: #23252e;
    z-index: 999999;
    h3 {
        margin-left: 60px;
        transition: transform .6s;
        a {
            font-family: 'gd-sage', serif;
            color: #ffffff;    
        }
        &:hover {
            transform: scale(1.1);
        }
    }
    ul {
        li {
            list-style: none;
            display: inline-block;
            position: relative;
            & + li {
                margin-left: 30px;
            }
            a {
                font-family: 'Inter', sans-serif;
                color: #ffffff;
            }
            &.active {
                &:after {
                    content: '';
                    opacity: 1;
                    position: absolute;
                    z-index: 99999999;
                    bottom: -8px;
                    left: 45%;
                    background: #ffffff;
                    height: 5px;
                    width: 5px;
                    border-radius: 50%;
                    transition: opacity .3s;
                }

            }
            &:after {
                content: '';
                opacity: 0;
                position: absolute;
                z-index: 99999999;
                bottom: -8px;
                left: 45%;
                background: #ffffff;
                height: 5px;
                width: 5px;
                border-radius: 50%;
                transition: opacity .3s;
            }
            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
    }
    #create-btn {
        margin-right: 60px;
        padding: 20px 30px;
        background: #ffffff;
        transition: transform .3s ease-in-out, box-shadow .4s ease-in-out;
        font-family: 'Inter', sans-serif;
        color: #000000;
        &:hover {
            transform: translateY(-5px);
            cursor: pointer;
            box-shadow: 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06);
            color: #23252e;
        }
    }
}

.page-content {
    padding-top: 20vh;
}

.dropdown-wrapper {
    position: absolute;
    z-index: 99;
    width: 200px;
    user-select: none;
    color: #adadad;
    & > *:hover {
        cursor: pointer;
    }
    filter: drop-shadow(0px 8px 20px rgba(0,0,0,0.13));
    &.active {
        .dropdown-header {
            border-color: #2f4f4f;
            .dropdown-header-title {
                color: #ffffff;
            }
        }
    }
    .dropdown-header {
        border: 2px solid rgb(90, 90, 90);
        padding: 10px 20px;
        background-color: #0000009a;
        transition: border ease-in-out .3s;
        .dropdown-header-title {
            transition: color ease-in-out .3s;
        }
    }
    .dropdown-list {
        background-color: #08080a;
        max-height: 0;
        transition: max-height ease-in-out 1s, border-width ease-in-out 1s;
        overflow: hidden;
        box-sizing: border-box;
        .dropdown-list-item {
            list-style: none;
            padding: 10px 20px;
            border-bottom: .5px solid #202020;
            transition: background-color ease-in-out .3s, color ease-in-out .3s;
            &:hover {
                background-color: #202020;
                cursor: pointer;
                color: #ffffff;
            }
        }
        &.visible {
            max-height: 150px;
            border: 2px solid #2f4f4f;
            border-top: none;
        }
    }
}
  
@media only screen and (max-width: 414px) {
    #nav {
        #hamburger { display: block; }
        #nav-links { display: none; }
        h1 {
            padding: 0 20px;
        }
    }
}

@keyframes pop-in {
    0% { transform: scale(0.3); }
    20% { transform: scale(1); }
    40% { transform: scale(0.8) }
    60% { transform: scale(1); }
    80% { transform: scale(0.9); }
    100% { transform: scale(1); }
}

@keyframes fly-out {
    0% { transform: translateY(0); }
    100% { transform: translateY(-100vh); }
}