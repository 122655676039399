#create {
    min-height: 100vh;
    width: 100%;
    background: #1d1e25;
    #heading, #subtitle {
        color: #ffffff;
    }
    #subtitle {
        margin-bottom: 50px;
        font-size: 18px;
        color: #ebebeb;
    }
    .page-content {
        width: 80%;
        margin: 0 auto;
        position: relative;
        #post {
            position: relative;
            label {
                display: block;
                font-size: 18px;
                color: #ebebeb;
                margin-bottom: 10px;
            }
            #writeLetter {
                margin-top: 100px;
                height: 100%;
                #details {
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 40px;
                    height: 100%;
                    color: #ffffff;
                    input {
                        -webkit-appearance: none;
                        width: 15vw;
                        font-size: 15px;
                        color: #adadad;
                        background-color: #0000009a;
                        padding: 15px 10px;
                        box-shadow: 0px 0px 40px 16px rgba(0,0,0,0.08);
                        transition: border-color ease-in-out .3s;
                        overflow: visible!important;
                        margin-bottom: 30px;
                        border: 2px solid transparent;
                        box-sizing : border-box;
                        &:focus {
                            border: 2px solid #2f4f4f;
                            color: #ffffff;
                        }
                    }
                    & > input[type="submit"] {
                        all: unset;
                        -webkit-text-fill-color: #000000;
                        background: #ffffff;
                        transition: transform ease-in-out .3s;
                        font-family: 'Inter', sans-serif;
                        color: #000000;
                        width: 100%;
                        font-size: 18px;
                        padding: 15px;
                        box-shadow: 0px 0px 40px 16px rgba(0,0,0,0.08);
                        margin-bottom: 30px;
                        border: none;
                        box-sizing : border-box;
                        text-align: center;    
                        &:hover {
                            cursor: pointer;
                            box-shadow: 0px 0px 40px 16px rgba(0,0,0,0.1);
                            transform: translateY(-5px);
                        }
                    }
                    #information {
                        margin-bottom: 28px;
                        dl {
                            dd {
                                float: left;
                                text-transform: uppercase;
                                color: #555555;
                            }
                            dt {
                                text-align: right;
                                margin-bottom: 5px;
                                color: #c4c4c4;
                            }
                        }
                    }
                    & > * {
                        display: block;
                    }
                    #switch {
                        span {
                            vertical-align: middle;
                        }
                    } 
                }
                #right {
                    position: absolute;
                    top: 31px;
                    display: inline-block;
                    width: 78%;
                    height: 500px;
                    textarea {
                        box-sizing : border-box;
                        min-width: 100%; max-width: 100%;
                        min-height: 100%; max-height: 100%;
                        resize: none;
                        padding: 15px;
                        background-color: #0000009a;
                        color: #ebebeb;
                        border: none;
                    }
                }
            }
        }
    // #post {
    //     margin: 0 10%;
    //     position: relative;
    //     label {
    //         font-weight: 600;
    //         font-size: 20px;
    //     }
    //     #dropdown-1 {
    //         margin-top: 10px;
    //     }
    //         .switch {
    //             display: flex!important;
    //             justify-content: space-between;
    //             align-items: center;
    //             padding: 0 20px;
    //             margin-bottom: 30px;
    //             .label {
    //                 font-weight: 600;
    //             }
    //             & span span {
    //                 cursor: auto!important;
    //             }
    //         }
    //     }
    //     #right {
    //         -webkit-appearance: none;
    //         display: inline-block;
    //         width: 50vw;
    //         min-height: 530px;
    //         position: relative;
    //         textarea {
    //             font-size: 23px;
    //             color: #000000;
    //             padding: 15px;
    //             box-shadow: 0px 0px 40px 16px rgba(0,0,0,0.08);
    //             border: 2px solid rgb(240, 246, 255);
    //             border-radius: 8px;
    //             min-width: 100%; max-width: 100%;
    //             min-height: 530px; max-height: 530px;
    //             resize: none;
    //             font-family: 'Inter', sans-serif;
    //             font-size: 18px;
    //             line-height: 36px;
    //             box-sizing : border-box;
    //         }
    //         #stats {
    //             position: absolute;
    //             right: 20px;
    //             bottom: -30px;
    //             font-weight: 600;
    //             color: #a0a0a0;
    //             #wordCount {
    //                 padding-right: 10px;
    //                 border-right: 2px solid rgb(196, 196, 196);
    //             }
    //             #saveStatus {
    //                 padding-left: 10px;
    //                 &.saved { color: rgb(102, 216, 102); }
    //                 &.not-saved { color: rgb(224, 90, 90); }
    //             }
    //         }
    //         #dropzone {
    //             p {
    //                 margin-bottom: 20px;
    //             }
    //             #preview {
    //                 height: 100%;
    //                 // width: 100%;
    //                 .previewPhoto {
    //                     height: 90%;
    //                     width: 90%;
    //                     margin: 0 auto;
    //                     background-position: center;
    //                     background-repeat: no-repeat;
    //                     background-size: contain;
    //                     display: inline-block;
    //                 } 
    //             }
    //         }
    //     }
    // }
    }
    .react-datepicker {
        border: none;
        background: #1d1e25;
        .react-datepicker__day {
            color: #c4c4c4;
            &:hover {
                border-radius: 0;
                background: rgba(0,0,0,0.2);    
                color: #ffffff;
            }
        }
        .react-datepicker__day--outside-month {
            color: #808080;
        }
        .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
            border-radius: 0;
            background: rgba(0,0,0,0.4);
            font-weight: normal;
            font-style: normal;
            color: #ffffff;
            &:hover {
                border-radius: 0;
                background: rgba(0,0,0,0.4);    
            }
        }
        .react-datepicker__header {
            border: none;
            background-color: rgba(0,0,0,0.4);
            margin: 0;
            padding: 15px 15px 0 15px;
            color: #ffffff;
        }
        .react-datepicker__input-time-container {
            border: none;
            background-color: rgba(0,0,0,0.4);
            margin: 0;
            padding: 15px;
            color: #ffffff;
            .react-datepicker-time__input {
                input {
                    margin-bottom: 0px!important;
                }
            }
        }
        .react-datepicker__current-month {
            font-family: 'Metropolis', sans-serif;
            font-weight: 700;
        }
        .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name {
            color: #ffffff;
        }
        .react-datepicker__navigation--previous {
            border-right-color: #424242;
            transition: border-right-color ease-in-out .1s;
            margin-top: 5px;
            &:hover {
                border-right-color: #ffffff;
            }
        }
        .react-datepicker__navigation--next {
            border-left-color: #424242;
            transition: border-left-color ease-in-out .1s;
            margin-top: 5px;
            &:hover {
                border-left-color: #ffffff;
            }
        }
    }
}

@media only screen and (max-width: 414px) {
    body {
        // background-color: red;
        #details, #right {
            display: block;
            width: 100%!important;
        }
        #details {
            input {
                width: 100%!important;
            }
        }

        #right {
            textarea {
                width: 100%;
            }
            #wordCount {
                right: 15px!important;
                bottom: 5px;
            }
        }
    }
}