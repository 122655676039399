#home {
    background: url("https://w.wallhaven.cc/full/3k/wallhaven-3krpqy.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    #opening-container {
        cursor: none;
        width: 100vw;
        height: 100vh;
        display: grid;
        place-items: center;
        color: #ffffff;
        background: #23252e;
        animation: fadeout .5s ease-in-out 2.5s 1 forwards;
        #opening-text-cover {
            background: #23252e;
            overflow: hidden;
            h2 {
                font-family: 'gd-sage', serif;
                animation: slideinout 3s 1;
                margin-bottom: 20px;
            }
        }
    }
    #center-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        animation: fadein 1.6s 2.5s 1 forwards;
        opacity: 0;
        display: grid;
        place-items: center;
        z-index: 999;
        cursor: default;
        #center-container-text-content {
            display: grid;
            place-items: center;
            #center-text-area {
                width: 25vw;
                height: 15vh;
                display: grid;
                place-items: center;
                background: #ffffff;
                position: relative;
                margin-bottom: 20px;
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #ffffff;
                    animation: fadeout 1.4s 4s 1 forwards;
                }
                h2 {
                    font-family: 'gd-sage', serif;
                    color: #000000;
                }    
            }   
            p {
                font-family: 'Inter', sans-serif;
                color: #ffffff;
                font-size: 18px;
                filter: drop-shadow(0px 0px 8px rgba(0,0,0,.5));
            }     
        }
    }
    #left-side {
        position: absolute;
        left: 0;
        top: 0;
        width: 50vw;
        height: 100vh;
        background: #23252e;
        opacity: 0;
        animation: leftright 2s 3s 1 forwards;
        display: flex;
        align-items: center;
    }
    #content {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        transform: scale(1.5);
        overflow: hidden;
        z-index: 9999;
        animation: zoomin 2s ease-out 3.3s 1 forwards;
        #content-navigation {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 8%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 20px;
            h3 {
                margin-left: 60px;
                transition: transform .6s;
                a {
                    font-family: 'gd-sage', serif;
                    color: #ffffff;    
                }
                &:hover {
                    transform: scale(1.1);
                }
            }
            ul {
                li {
                    list-style: none;
                    display: inline-block;
                    position: relative;
                    & + li {
                        margin-left: 30px;
                    }
                    a {
                        font-family: 'Inter', sans-serif;
                        color: #ffffff;
                    }
                    &:after {
                        content: '';
                        opacity: 0;
                        position: absolute;
                        z-index: 99999999;
                        bottom: -8px;
                        left: 45%;
                        background: #ffffff;
                        height: 5px;
                        width: 5px;
                        border-radius: 50%;
                        transition: opacity .3s;
                    }
                    &:hover {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
            #create-button {
                margin-right: 60px;
                padding: 20px 30px;
                background: #ffffff;
                transition: transform .3s ease-in-out, box-shadow .4s ease-in-out;
                font-family: 'Inter', sans-serif;
                color: #000000;
                &:hover {
                    transform: translateY(-5px);
                    cursor: pointer;
                    box-shadow: 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06);
                    a {
                        color: #23252e;
                    }
                }
            }
        }
        #content-left {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            a {
                position: absolute;
                left: -20px;
                transform: rotate(-90deg);
                font-family: 'Inter', sans-serif;
                color: #797b81;
                text-decoration: none;
            }
        }
    }
}

@keyframes zoomin{
    0% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes slideinout {
    0% {
        transform: translateY(90px);
        opacity: 0;
    }
    50% {
        transform: translateY(0px);
        opacity: 1;
    }
    70% {
        transform: translateY(0px);
        opacity: 0.8;
    }
    100% {
        transform: translateY(-50px);
        opacity: 0;
    }
}

@keyframes slideout {
    0% {
        transform: translateY(0px);
        opacity: 0;
    }
    100% {
        transform: translateY(-10px);
        opacity: 1;
    }
}

@keyframes leftright {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// #homepage {
//     min-height: 100vh;
//     overflow: hidden;
//     header {
//         margin: 15% 10%;
//         position: relative;
//         h1 {
//             font-size: 64px;
//             line-height: 72px;
//         }
//         h3 {
//             font-size: 40px;
//             line-height: 44px;
//             font-weight: 600;
//             margin-bottom: 15vh;
//         }
//         #button {
//             font-size: 23px;
//             color: #000000;
//             padding: 15px 60px;
//             box-shadow: 0px 0px 40px 16px rgba(0,0,0,0.13);
//             border-radius: 8px;
//             transition: .3s;
//             overflow: visible!important;
//             &:hover {
//                 box-shadow: 0px 12px 40px 16px rgba(0,0,0,0.13);
//                 color: #1d1d1d;
//             }
//         }
//     }
//     #homepage-art {
//         position: absolute;
//         top: 30vh;
//         right: 10vw;
//         width: 35vw;
//     }
// }

// @media only screen and (max-width: 414px) {
//     header {
//         margin-top: 15vh!important;
//     }
//     #blob-2 {
//         display: none;
//     }
// }