#letters {
    background: #1d1e25;
    min-height: 100vh;
    h3, .subheading {
        color: #ffffff;
        padding-left: 15px;
    }

    .subheading {
        margin-bottom: 50px;
        font-size: 18px;
        color: #ebebeb;
    }
    #letter {
        overflow-x: hidden;
        z-index: 999999;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        transform: translateY(-100vh);
        height: 100vh;
        width: 100vw;
        background: #1d1e25;
        display: grid;
        place-items: center;
        #letter-controls {
            position: sticky;
            top: 0;
            margin-bottom: 50px;
            width: 100%;
            text-align: right;
            background: #23252e;
            svg {
                transition: rotate .2s;
                &:hover {
                    stroke: #111114;
                    cursor: pointer;
                    rotate: 90deg;
                }
            }
        }
        #letter-header {
            text-align: center;
            h3 {
                color: #ffffff;
            }
        }
        #letter-content {
            width: 40%;
            #letter-body {
                p {
                    font-size: 18px;
                    color: #ebebeb;
                    margin-bottom: 30px;
                }
            }
            #letter-wordcount {
                margin-top: 10vh;
                text-align: center;
                font-size: 15px;
                color: #c5c5c5;
                z-index: 99999999;
            }
            &:after {
                content: '';
                display: block;
                position: sticky;
                bottom: 0;
                width: 100%;
                height: 10vh;
                z-index: 9;
                background: linear-gradient(to top, #1d1e25, rgba(0, 128, 0, 0));
            }
        }
        &.viewable {
            animation: slidedown 1.5s ease-out 1 forwards;
        }
        &.close {
            animation: slideup .7s ease-out 1 forwards;
        }
    }
    .page-content {
        width: 80%;
        margin: 0 auto;
        position: relative;
        #note { margin-bottom: 30px; }
        #letters-preview {
            width: 55%;
            display: inline-block;
            position: relative;
            .preloaded {
                div {
                    margin-bottom: 30px;
                    &:nth-child(1) {
                        position: relative;
                        overflow: hidden;
                        background: rgba(0,0,0,0.4);
                        width: 400px;
                        height: 20px;
                        margin-bottom: 10px;
                        &:after {
                            content: '';
                            top: 0;
                            opacity: .1;
                            transform: translateX(100%);
                            width: 100%;
                            height: 220px;
                            position: absolute;
                            z-index: 1;
                            animation: slide 2s infinite;
                            background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.4) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
                        }
                    }
                    &:nth-child(2) {
                        position: relative;
                        overflow: hidden;
                        background: rgba(0,0,0,0.4);
                        width: 600px;
                        height: 40px;
                        &:after {
                            content: '';
                            top: 0;
                            opacity: .1;
                            transform: translateX(100%);
                            width: 100%;
                            height: 220px;
                            position: absolute;
                            z-index: 1;
                            animation: slide 2s infinite;
                            background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.4) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
                        }
                    }
                }
            }   
            .letter {
                position: relative;
                padding: 15px;
                transition: background ease-in-out .3s;
                &:hover {
                    background: rgba(0,0,0,0.4);
                    cursor: pointer;
                }
                & > * {
                    user-select: none;
                }
                &.unread {
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        background: rgb(0, 183, 255);
                        border-radius: 50%;
                        width: 10px;
                        height: 10px;
                        left: 0;
                        top: 45%;
                    }
                }
                .letter-title {
                    color: #ffffff;
                    .pinned {
                        position: absolute;
                        left: -30px;
                        top: -2px;
                        color: #A0C4FF;
                    }
                    .title-icon {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
                .letter-preview {
                    color: #ebebeb;
                }
            }
            // &:after {
            //     content: '';
            //     display: block;
            //     width: 100%;
            //     height: 20%;
            //     position: absolute;
            //     bottom: 0;
            //     left: 0;
            //     background: linear-gradient(to top, #1d1e25, rgba(0, 128, 0, 0))
            // }
        }
        #stats {
            display: inline-block;
            vertical-align: top;
            position: fixed;
            right: 20vw;
            padding: 20px;
            border: 2px solid #2d2e36;
            width: 250px;
            height: 460px;
            transition: background .4s ease-in-out;
            h4 {
                color: #ffffff;
                margin-bottom: 10px;
                user-select: none;
            }
            p {
                color: #ebebeb;
                font-size: 18px;
                margin-bottom: 15px;
                user-select: none;
            }
            span {
                font-weight: 600;
                user-select: none;
            }
            &:hover {
                background: #2d2e36;
            }
        }
        .hiddenMessage {
            position: relative;
            background: rgb(240, 240, 240);
            padding: 3px 5px;
            border-radius: 3px;
        }
    }
}

@keyframes slide {
	0% {transform:translateX(-100%);}
	100% {transform:translateX(100%);}
}

@keyframes slidedown {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes slideup {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-100%);
    }
}

@media only screen and (max-width: 414px) {
    #letters {
        width: 90%;
    }
}